export function isObject(input) {
  return typeof input === "object" && input !== null && !Array.isArray(input);
}
export function isFunction(input) {
  return typeof input === "function";
}
export function isString(input) {
  return typeof input === "string";
}
export function isNumber(input) {
  return typeof input === "number" && !Number.isNaN(input);
}
export function isInteger(input) {
	return typeof input === "number" && (input | 0) === input;
}
export function isEmptyArray(input) {
  return Array.isArray(input) && input.length === 0;
}
export function isPopulatedArray(input) {
	if (!Array.isArray(input)) {
		throw new Error("input is not an array.");
	}
  return input.length > 0;
}
export function isResponse(input) {
  return input instanceof Response;
}
export function isBlob(input) {
  return input instanceof Blob && !(input instanceof File);
}
export function isDate(input) {
	return input instanceof Date;
}
export function isDefined(input) {
	return input !== undefined && input !== null;
}
export function isAwsKey(input) {
	if (!isString(input)) {
		throw new Error("input is not a string.");
	}
	return /^<.*>$/.test(input);
}
export function isDevelopmentEnvironment() {
	return ['localhost'].includes(window.location.hostname);
}
export function isServerErrorResponse(response) {
	return response.status >= 500 && response.status < 600;
}
export function isBlockingStatusCode(statusCode) {
	if (!isNumber(statusCode)) {
		throw new Error("provided status code is not a number.");
	}
	return [401, 403, 404, 500, 502, 503].includes(statusCode);
}
export function isIterable(obj) {
	if (obj === null || obj === undefined) {
		return false;
	}
	return typeof obj[Symbol.iterator] === 'function';
}
export function isFutureDate(date) {
	if (!isDate(date)) {
		return false;
	}
	return new Date(Date.now()) < date;
}
export function isPastDate(date) {
	if (!isDate(date)) {
		return false;
	}
	return new Date(Date.now()) > date;
}

// Belonging assertions.
export function hasProperty(property, object) {
	return isObject(object) && object.hasOwnProperty(property);
}
export function hasRole(role, input) {
  return input && input.role === role;
}
