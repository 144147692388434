import { isDefined, isObject, isString } from "./assertions";

/**
 * Function which determines number of arguments expected by the function.
 * @param {Function} fn
 * @returns {number}
 */
export function arity(fn) {
  return typeof fn === "function" ? fn.length : 0;
}
/**
 * Breaks function with multiple arguments into partial function
 * executed only once all of the arguments are provided.
 * @param {Function} fn
 * @returns {(...args: any[]) => any}
 * @example
 * const sum = (a,b,c) => a+b+c;
 * curry(sum, 10, 20, 30)();
 * curry(sum)(10, 20, 30);
 * curry(sum)(10)(20, 30);
 * curry(sum)(10)(20)(30);
 */
export function curry(fn) {
  const argumentsLength = arity(fn);
  return (function takeArguments(initialArguments) {
    return function takeCurriedArguments() {
      const availableArguments = initialArguments.concat(Array.from(arguments));
      return availableArguments.length >= argumentsLength ?
        fn.apply(this, availableArguments) :
        takeArguments(availableArguments);
    };
  })(Array.from(arguments).slice(1));
}
/**
 * Chain function execution in a clockwise direction.
 * @param  {...Function} fns
 * @returns {(argument: any) => Function}
 */
export function pipe(...fns) {
  return function takeArgument(argument) {
    return fns.reduce((output, fn) => typeof fn === "function" ? fn(output) : (output) => output, argument);
  }
}
/**
 * Chain function execution in a counter clockwise direction.
 * @param  {...any} fns
 * @returns
 */
export function compose(...fns) {
	return function takeArgument(argument) {
		return fns.reduceRight((output, fn) => typeof fn === "function" ? fn(output) : (output) => output, argument);
	}
}
/**
 * Extract specified property from the underlying object.
 * @param {string} property CSV property path.
 * @param {object} object
 * @returns
 */
export function pluck(property, object, defaultValue) {
	if (isDefined(property)) {
		if (!Array.isArray(property) && !isString(property)) {
			throw new Error(`Provided property cannot be ${typeof property}.`);
		}

		if (!isObject(object)) {
			throw new Error(`Cannot extract property from not an object.`);
		}
	}

	if (isObject(object)) {
		if (!isDefined(property)) {
			throw new Error(`Cannot proceed with plucking without provided property.`);
		}
	}
  const properties = Array.isArray(property) ? property : property.split(".");
  const output = properties.reduce(function pluckExisting(nestedObject, property) {
    if (nestedObject && property in nestedObject) {
      return nestedObject[property];
    }
    return null;
  }, object);
	return output !== null ? output : defaultValue;
}
/**
 * Run data through defined set of steps.
 * Produced end result is the same as if data were processed through `compose` function
 * but in a more OOP style.
 * @param {*} data
 */
export function processData(data) {
	let executed = false;
	const queue = [];
	function step(callback) {
		if (!executed) {
			queue.push(callback);
			return this;
		}
	}
	function run() {
		if (!executed) {
			executed = true;
			return compose(...queue)(data);
		}
	}
	return {step, run};
}
/**
 * Not operational function.
 */
export function noop() { };
/**
 *
 */
export const partial = (fn, ...presetArguments) => {
	return function takeRest(...restArguments) {
		return fn.apply(this, [...presetArguments, ...restArguments]);
	}
}
/**
 * Chain asynchronous methods.
 * @param  {...any} fns
 * @returns {Promise<any>}
 */
export function asyncPipe(...fns) {
	return async function takeInitialValue(initialValue) {
		let output = initialValue;
		for (const fn of fns) {
			output = await fn(output, fn);
		}
		return output;
	}
}
/**
 * Ensure that the callback is called after {delay} since tha last try.
 * @param {*} callback
 * @param {number} delay
 * @returns
 */
export function debounce(callback, delay) {
	let timer;
	return function () {
		if (timer) window.clearTimeout(timer);
		const context = this;
		const args = arguments;
		timer = window.setTimeout(() => {
			timer = null;
			callback.apply(context, args);
		}, delay);
	}
}
/**
 * Ensure that the function is callable every {delay} milliseconds
 * no matter how many times we try to call the function.
 * @param {*} callback
 * @param {*} delay
 * @returns
 */
export function throttle(callback, delay) {
	let running = false;
	return function () {
		if (running) return false;
		running = true;
		callback.apply(this, arguments);
		window.setTimeout(() => {
			running = false;
		}, delay);
	}
}
/**
 * Ensure that the callback function is executed only once.
 * @param {*} callback
 * @param {*} context
 * @returns
 */
export function once(callback, context = null) {
	let output;
	return function () {
		if (typeof callback === "function") {
			output = callback.apply(context || this, arguments);
			callback = null;
		}
		return output;
	}
}

export function ensureFunction(input) {
	return typeof input === "function" ? input : noop;
}

export function clamp(min, max, value) {
	return Math.max(min, Math.min(max, value));
}
