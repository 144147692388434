import { URL_REGEX_SIMPLE } from "../constants/patterns";
import { getContentTypeHeader } from "../util/api";
import { isFunction, isObject, isString } from "../util/assertions";

class RequestBuilder {
	/**
	 * Create new instance of the RequestBuilder
	 * @returns {RequestBuilder} new `RequestBuilder` instance
	 */
	static builder() {
		return new RequestBuilder();
	}

	/**
	 * @constructor
	 */
	constructor() {
		this.reset = this.reset.bind(this);
		this.setURL = this.setURL.bind(this);
		this.setMethod = this.setMethod.bind(this);
		this.setHeaders = this.setHeaders.bind(this);
		this.appendHeaders = this.appendHeaders.bind(this);
		this.setBody = this.setBody.bind(this);
		this.setMode = this.setMode.bind(this);
		this.setCredentials = this.setCredentials.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.sortBy = this.sortBy.bind(this);
		this.sortDirection = this.sortDirection.bind(this);
		this.limit = this.limit.bind(this);
		this.where = this.where.bind(this);
		this.abort = this.abort.bind(this);
		this.build = this.build.bind(this);
		this.reset();
	}
	/**
	 * Reset configuration to default values.
	 */
	reset() {
		this.headers = new Headers([
			["content-type", "text/plain;charset=UTF-8"]
		]);
		this.method = "GET";
		this.body = null;
		this.mode = "cors";
		this.credentials = "same-origin";
		this.cache = "default";
		this.redirect = "follow";
		this.referrer = "about:client";
		// this.integrity = null;
		this.URL = "";
		this.params = new URLSearchParams();
		this.abortController = new AbortController();
		return this;
	}
	/**
	 * Configure Request URL.
	 * @param {string} URL
	 * @default ""
	 */
	setURL(URL = "") {
		if (!isString(URL)) {
			console.debug("Provided URL is not type of string.");
			this.URL = "";
			return this;
		}
		URL = URL.trim();
		if (!URL_REGEX_SIMPLE.test(URL)) {
			console.debug("Provided URL is not a valid URL.");
			this.URL = "";
			return this;
		}
		this.URL = URL;
		return this;
	}
	/**
	 * Configure Request method.
	 * @param {"GET"|"HEAD"|"POST"|"PUT"|"PATCH"|"DELETE"} nextMethod
	 * @default "GET"
	 */
	setMethod(method = "GET") {
		if (!isString(method)) {
			console.debug(`Provided method name is not a string.`);
			this.method = "GET";
			return this;
		}
		method = method.toUpperCase();
		if (!["HEAD", "OPTIONS", "GET", "POST", "PUT", "PATCH", "DELETE"].includes(method)) {
			console.debug(`Provided method ${method} is not a valid HTTP method.`);
			this.method = "GET";
			return this;
		}
		this.method = method;
		return this;
	}
	/**
	 * Configure Request headers.
	 * @param {{[name: string]: string}|Headers} headers
	 * @returns
	 */
	setHeaders(headers) {
		if (headers === null || headers === undefined) {
			this.headers = new Headers();
			return this;
		}
		if (headers instanceof Headers) {
			this.headers = headers;
			return this;
		}
		if (Object.prototype.toString.call(headers) === "[object Object]") {
			const nextHeaders = new Headers();
			for (const [key, value] of Object.entries(headers)) {
				nextHeaders.set(key.toLowerCase(), value);
			}
			this.headers = nextHeaders;
		}
		return this;
	}
	/**
	 * Configure single header value.
	 * @param {string} name
	 * @param {string} value
	 * @returns
	 */
	setHeader(name, value) {
		if (value === null) {
			return this;
		}
		this.headers.set(name, value);
		return this;
	}
	/**
	 * Append additional value to the header.
	 * @param {object} headers
	 * @returns
	 */
	appendHeaders(headers) {
		for (const name in headers) {
			this.headers.append(name, headers[name]);
		}
		return this;
	}
	/**
	 * Configure Request body.
	 * @param {string|URLSearchParams|FormData} nextBody
	 * @default "{}"
	 */
	setBody(body) {
		if (body === null || body === undefined) {
			this.headers.delete("content-type");
			this.body = body;
			return this;
		}
		const contentType = getContentTypeHeader(body);
		if (contentType) {
			this.headers.set("content-type", contentType);
		} else {
			this.headers.delete("content-type");
		}
		if (body instanceof FormData || body instanceof URLSearchParams) {
			this.body = body;
		} else if (isObject(body) || isFunction(body.toJSON)) {
			this.body = JSON.stringify(body);
		} else {
			this.body = body;
		}
		return this;
	}
	/**
	 * Configures request mode.
	 * @param {"cors"|"no-cors"|"same-origin"|"navigate"} nextMode
	 * @default "cors"
	 */
	setMode(nextMode = "cors") {
		this.mode = nextMode;
		return this;
	}
	/**
	 * Configure request credentials.
	 * @param {string} nextCredentials
	 * @default "same-origin"
	 */
	setCredentials(nextCredentials = "same-origin") {
		this.credentials = nextCredentials;
		return this;
	}
	/**
	 * Configure request redirect.
	 * @param {"follow"|"error"|"manual"} nextRedirect
	 * @default "follow"
	 */
	setRedirect(nextRedirect = "follow") {
		this.redirect = nextRedirect;
		return this;
	}
	/**
	 * Configure Request params string pair.
	 * @param {string} field
	 * @param {*} value
	 */
	where(field, value) {
		if (value === null || value === undefined) {
			this.params.delete(field);
			return this;
		}
		this.params.set(field, value);
		return this;
	}
	/**
	 * Configure search params.
	 * @param {{[name: string]: string}|URLSearchParams} params
	 * @returns
	 */
	setParams(params) {
		if (params instanceof URLSearchParams) {
			this.params = params;
		}
		if (Object.prototype.toString.call(params) === "[object Object]" && !Array.isArray(params)) {
			for (const [key, value] of Object.entries(params)) {
				this.params.set(key, value);
			}
		}
		return this;
	}
	/**
	 * Configure Request sorting field.
	 * @param {string} field
	 * @deprecated
	 */
	sortBy(field) {
		this.params.set("sortBy", field);
		return this;
	}
	/**
	 * Configure Request sorting direction.
	 * @param {"asc"|"desc"} direction
	 * @deprecated
	 */
	sortDirection(direction) {
		this.params.set("direction", direction);
		return this;
	}
	/**
	 * Configure request page size.
	 * @param {*} limit
	 * @returns
	 */
	limit(limit) {
		this.params.set("pageSize", limit);
		return this;
	}
	/**
	 * Abort sent request.
	 * @returns {Promise<boolean>}
	 */
	abort() {
		return new Promise((resolve) => {
			if (!this.abortController.signal.aborted) {
				this.abortController.abort();
				this.abortController.signal.addEventListener('abort', () => {
					resolve(true);
				}, { once: true });
				return;
			}
			resolve(true);
		});
	}
	/**
	 * Build new `Request` instance.
	 * @returns
	 */
	build() {
		this.abortController = new AbortController();
		return new Request(this.URL + (["GET", "HEAD", "POST"].includes(this.method) ? "?" + this.params.toString() : ""), {
			method: this.method,
			headers: this.headers,
			body: ["GET", "HEAD"].includes(this.method) ? null : this.body,
			cache: this.cache,
			mode: this.mode,
			redirect: this.redirect,
			credentials: this.credentials,
			referrer: this.referrer,
			integrity: this.integrity,
			signal: this.abortController.signal
		});
	}
	/**
	 * Converts request builder to plain structured object.
	 * @returns
	 */
	toJSON() {
		const output = {};
		output.url = this.URL + this.params.toString();
		output.method = this.method;
		output.headers = {};
		for (const [key, value] of this.headers.entries()) {
			output.headers[key] = value;
		}
		output.mode = this.mode;
		output.credentials = this.credentials;
		output.redirect = this.redirect;
		output.body = this.body;
		output.signal = this.abortController.signal;
		return output;
	}
}

export default RequestBuilder;
