import { isDefined, isObject } from "./assertions";

export function toDropdownOption(input) {
	if (!isObject(input)) {
		console.debug("Provided input is not an object.");
		return { value: "", label: "" }
	};
	return {
		value: isDefined(input.id) ? input.id : "",
		label: isDefined(input.name) ? input.name : ""
	};
}
/**
 *
 * @param {{
 *  id?: string|number;
 *  firstName?: string;
 *  lastName?: string;
 * }} userInput
 * @returns
 */
export function toUserDropdownOption(userInput) {
	if (!isObject(userInput)) {
		console.debug("Provided input is not an object.");
		return { value: "", label: "" };
	}
	return {
		value: userInput.id,
		label: toFullName(userInput)
	}
}
/**
 *
 * @param {{
 * 	firstName: string,
 * 	lastName: string
 * }} input
 * @returns {string|{
 * 	firstName: string,
 * 	lastName: string
 * }}
 */
export function toFullName(input) {
	if (!isObject(input)) return "";
	return ((input.firstName || "") + " " + (input.lastName || "")).trim();
}
/**
 * Convert megabytes into corresponding number of bytes.
 * @param {number} mb
 * @returns
 */
export function fromMegabyteToByte(mb) {
	return mb * 2 ** 20;
}
/**
 * Convert kilobytes into corresponding number of bytes.
 * @param {number} kb
 * @returns
 */
export function fromKilobyteToByte(kb) {
	return kb * 2 ** 10;
}
/**
 *
 * @param {number} days
 * @returns {number}
 */
export function fromDaysToMilliseconds(days) {
	return days * 86_400_000
}
/**
 * Convert data from the backend to the Material.UI Datepicker format.
 * @param {string} format String combined with special format characters:
 * - `YYYY` - Full year,
 * - `YY` - Last two digits of a year,
 * - `MM` - Month,
 * - `DD` - Day,
 * - `HH` - Hours,
 * - `mm` - Minutes,
 * - `ss` - Seconds,
 * - `sss` - Milliseconds
 * @param {Date} date
 * @param {{
 *  utc?: boolean
 * }}
 * @example
 * fromDateToString(new Date('23/04/2021'), 'yyyy-mm-dd')
 * fromDateToString(Date.now(), 'mm/dd/yy')
 * @returns
 */
export function fromDateToString(format, date, options = {}) {
	let input = date;
	// In case we pass timestamp from the `Date.now()`
	if (typeof input === 'number' || typeof input === "string") {
		input = new Date(input);
	}
	const map = {
		sss: input.getMilliseconds(),
		ss: `0${options.utc ? input.getUTCSeconds() : input.getSeconds()}`.slice(-2),
		mm: `0${options.utc ? input.getUTCMinutes() : input.getMinutes()}`.slice(-2),
		HH: `0${options.utc ? input.getUTCHours() : input.getHours()}`.slice(-2),
		MM: `0${options.utc ? input.getUTCMonth() + 1 : input.getMonth() + 1}`.slice(-2),
		DD: `0${options.utc ? input.getUTCDate() : input.getDate()}`.slice(-2),
		YY: input.getFullYear().toString().slice(-2),
		YYYY: input.getFullYear()
	}
	return format.replace(/MM|DD|YYYY|YY|HH|mm|sss|ss/g, matched => map[matched])
}
/**
 * @param {string} extension
 * @returns
 */
export function toMimeType(extension) {
	const MimeTypeMap = new Map([
		['pdf', 'application/pdf'],
		['jpg', 'image/jpg'],
		['jpeg', 'image/jpeg'],
		['png', 'image/png'],
		["gif", "image/gif"],
		["xls", "application/vnd.ms-excel"],
		["xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
		["doc", "application/msword"],
		["docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
		["eml", "message/rfc822"]
	]);

	return MimeTypeMap.get(extension);
}

export function bytesToReadableSize(bytes = 0) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	function getPowerAndMultiplier(bytes, power = 0) {
		if (bytes >= 1024) {
			return getPowerAndMultiplier(bytes / 1024, power + 1);
		}
		return [bytes, power];
	}

	const [size, power] = getPowerAndMultiplier(bytes);
	const number = Number(size.toFixed(1));
	return `${number} ${sizes[power]}`;
}

/**
 * Convert Date object to backend compatible string.
 * @param {Date} date
 * @returns
 */
export function fromDateToBackendDateString(date) {
	return date.toISOString();
}
